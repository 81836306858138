const Video =[{
    id:"1",
    title:"React Js",
    channel:"usman",
    views:"10K Views",
    time:"1 year ago",
    verified:true
   },{
    id:"2",
    title:"Next Js",
    channel:"usman",
    views:"1K Views",
    time:"1 month ago",
    verified:false
    },{
    id:"3",  
    title:"Angular Js",
    channel:"usman",
    views:"1M Views",
    time:"5 year ago",
    verified:true
    }
]

export default Video;