import './Video.css'


function Video({id,title,channel,views,time,verified}){

    return (
      <>
      <div className="youtube-card">
        <img className="thumb" src={`https://picsum.photos/id/${id}/200/200`} alt="Alan L. Hart"/>
        <div className="title">{title}</div>
        <div className="channel">{channel} {verified && '☑️'}</div>
        <div className="views_time">{views}<span>.</span>{time}</div>
      </div>             
     </>  
    );
}

export default Video;