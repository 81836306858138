
import Video from './components/Video';
import videos from './data/data';

function App(){

  return(  

  <div className='youtube'>

  {
    videos.map(video=>
      <Video
      key={video.id}
      id={video.id} 
      verified={video.verified} 
      title={video.title} 
      channel={video.channel} 
      views={video.views} 
      time={video.views}/>
      )
  }

  </div>

  )
  
}
  
  export default App;